body {
	margin: 0;
	padding: 0;
	background: #89f99b;
	color: white;
	//background: url('/site/themes/oliver2019/Wilpena_Pound_and_St._Mary_Peak,_South_Australia.jpg');
}

.btn-primary {
	background-color: #2932b8;
	border: none;
}
a {
	color: #2932b8;
}

canvas {
	width:100%;
	position: fixed;
	top:0;
	z-index:100;
	pointer-events: none;
}

.container-fluid {

	margin: 0;
	padding: 0;
	min-height: 100vh;

}

.carousel {
	height: 750px;
	margin-top: 20px;
	img {
		max-width: 100%;
		max-height: 550px;
	}
}
.carousel-indicators {
	bottom:0;
}

h1, h2 {
	font-size: 100px;
	margin: 0;
	padding: 0;
	font-family: 'Pacifico';
}

h1 {
	margin-top: 100px;
}

p {
	margin: 0;
	padding: 10px;
}

.scrollme {
	position: fixed;
	right: 0;
	top: 50%;
	padding: 0;
	margin: 0;
	margin-right: -180px;
	margin-top: -100px;
	transform: rotate(90deg);
}

.gradient1 {
	h1, h2 {
		color: #fb9076;
		text-shadow: 4px 3px #2932b8;
	}
	background: #89f99b; /* Old browsers */
	background: -moz-linear-gradient(top, #89f99b 0%, #2932b8 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #89f99b 0%, #2932b8 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #89f99b 0%, #2932b8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#89f99b', endColorstr='#2932b8',GradientType=0 ); /* IE6-9 */
}

.gradient2 {
	h1, h2 {
		color: #89f99b;
		text-shadow: 4px 3px #eb55ab;
	}
	background: #eb55ab; /* Old browsers */
	background: -moz-linear-gradient(top, #2932b8 0%, #eb55ab 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #2932b8 0%, #eb55ab 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #2932b8 0%, #eb55ab 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2932b8', endColorstr='#eb55ab',GradientType=0 ); /* IE6-9 */
}

.gradient3 {
	h1, h2 {
		color: #89f99b;
		text-shadow: 4px 3px #2932b8;
	}
	background: #fb9076; /* Old browsers */
	background: -moz-linear-gradient(top, #eb55ab 0%, #fb9076 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #eb55ab 0%, #fb9076 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #eb55ab 0%, #fb9076 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb55ab', endColorstr='#fb9076',GradientType=0 ); /* IE6-9 */
}

.gradient4 {
	h1, h2 {
		color: #2932b8;
		text-shadow: 4px 3px #89f99b;
	}
	background: #89f99b; /* Old browsers */
	background: -moz-linear-gradient(top, #fb9076 0%, #89f99b 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #fb9076 0%, #89f99b 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #fb9076 0%, #89f99b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb9076', endColorstr='#89f99b',GradientType=0 ); /* IE6-9 */
}


